<template>
    <div id="pageTable">     
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container> 
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :columns="columns"
                        :tableName="'Préstamos'"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="showOptions"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft" v-if="showOptions">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsClientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value_filtro"
                                ></v-autocomplete>
                                </v-col>
                            </template>
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Empleados"
                                    :items="itemsEmpleados"
                                    item-text="nombre_completo"
                                    item-value="id"
                                    persistent-hint
                                    v-model="empleado_value"
                                    >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0 my-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo prestamo"
                                    v-model="tipo_incidencia_value"
                                    :items="deducciones"
                                    item-text="codigoNombre"
                                    item-value="id"
                                    persistent-hint
                                    class="pa-0 ma-0"
                                    required 
                                />
                            </v-col>
                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                <VuetifyDatePicker
                                    outlined
                                    v-model="fecha_prestamo"
                                    label="Fecha de préstamo"
                                    placeholder="dd/mm/aaaa"
                                    ref="FechaPrestamoBA"
                                />
                            </v-col>
                        </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td v-if="rol!='empleado'"><div>{{item.empleado.nombre_completo}}</div></td>
                                <td><div>{{dateFormat(item.fecha_prestamo)}}</div></td>
                                <td><div>{{formatoMoneda(item.monto)}}</div></td>
                                <td><div>{{formatoMoneda(item.saldo)}}</div></td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" class="botonHover" icon fab dark small @click="vista_abonos(item)">
                                                    <v-icon class="tamIconoBoton invertirColorBotones">description</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Abonos</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="rol!='empleado'">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal('update', item)">
                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom v-if="rol!='empleado'">
                                            <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(item)">
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0" class="borde-card">
                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ tituloModal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto">
                                    <div id="padre" v-show="isLoadingModal">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    
                                    <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col v-if="rol == 'root'" cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Clientes" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Clientes"
                                                        :items="itemsClientes"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="cliente_value"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Empleados"
                                                        :items="itemsEmpleados"
                                                        item-text="nombre_completo"
                                                        item-value="id"
                                                        persistent-hint
                                                        v-model="prestamo.empleado_id"
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    >
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0 my-0">
                                                <ValidationProvider v-slot="{ errors }" name="Empleado" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        label="Tipo prestamo"
                                                        v-model="prestamo.tipo_incidencia_id"
                                                        :items="deducciones"
                                                        item-text="codigoNombre"
                                                        item-value="id"
                                                        persistent-hint
                                                        class="pa-0 ma-0"
                                                        required 
                                                        :error-messages="errors" 
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                                <ValidationProvider v-slot="{ errors }" name="Descripción" rules="required">
                                                    <v-text-field 
                                                        outlined
                                                        label="Descripción" 
                                                        class="pa-0 ma-0" 
                                                        v-model="prestamo.descripcion" 
                                                        :error-messages="errors" 
                                                        required
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6"  class="py-0">
                                                <VuetifyDatePicker
                                                    outlined
                                                    rules="required"
                                                    v-model="prestamo.fecha_prestamo"
                                                    label="Fecha de préstamo"
                                                    placeholder="dd/mm/aaaa"
                                                    ref="FechaPrestamo"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    rules="required"
                                                    label="Monto"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="prestamo.monto"
                                                    v-bind:options="options"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0 d-flex">
                                                <v-switch
                                                    v-model="prestamo.aplica_cobro"
                                                    label="Aplicar descuento"
                                                ></v-switch>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2 outlined_v_icon">info</v-icon>
                                                    </template>
                                                    <span class="pb-7 textTooltip">
                                                        Si se selcciona la opción se le descontara <br>
                                                        automaticamente vía nómina
                                                    </span>
                                                </v-tooltip>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                                <VuetifyMoneyValidate
                                                    outlined
                                                    label="Descuento"
                                                    class="pa-0 ma-0"
                                                    prefix="$"
                                                    v-model="prestamo.descuento"
                                                    v-bind:options="options"
                                                    :disabled="disableDescuento"
                                                />
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                        @click="cerrarModal()" 
                                        :disabled="isSaving"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </template>

        </v-container>
    </div>
</template>

<script>
import Datatable from '@/components/g-datatable/Datatable.vue';
import queries from '@/queries/Prestamos';
import Notify from '@/plugins/notify';
import apiClientes from '@/api/clientes';
import IncidenciasNominasApi from '@/api/nominas/incidencias';
import apiPrestamos from '@/api/nominas/prestamos';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';


export default {
    components: {
        VuetifyDatePicker,
        VuetifyMoneyValidate,
        'data-table': Datatable,
    },
    data() {
        return {
            datosLogin      : null,
            rol             : null,
            columns         : [
                {
                    label: 'Empleado',
                    name: 'nombre',
                    filterable: false
                },
                {
                    label: 'Fecha',
                    name: 'fecha_prestamo',
                    filterable: true
                },
                {
                    label: 'Monto',
                    name: 'monto',
                    filterable: true
                },
                {
                    label: 'Saldo',
                    name: 'saldo',
                    filterable: true
                },
                {
                    label: 'Opciones',
                    name: 'opciones',
                    align:'center',
                    filterable: false
                }
            ],
            filters         : {AND:[]},
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },
            prestamo        : {
                id                  : null,
                cliente_id          : null,
                empleado_id         : null,
                tipo_incidencia_id  : null,
                descripcion         : null,
                monto               : null,
                fecha_prestamo      : null,
                aplica_cobro        : true,
                descuento           : 0,
            },
            accion          : null,
            tituloModal     : null,
            dialog          : false,
            isSaving        : false,
            imagen          : '',
            cliente_value   : null,
            cliente_value_filtro   : null,
            empleado_value  : null,
            tipo_incidencia_value : null,
            fecha_prestamo  : null,
            itemsClientes   : [],
            itemsEmpleados  : [],
            empleado_type   : "add",
            menu            : false,
            menu_filter            : false,
            deducciones     : [],
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            isLoadingModal  : false,
            disableDescuento : false,

        }
    },
    computed:{
        showOptions: function(){
            if(this.rol == 'empleado'){
                return false;
            }
            else{
                return true;
            }
        }
    },
    watch : {
        cliente_value: function(val){
            this.empleado_type = "add";
            if(val != null){
                this.getEmpleados(val);
                this.getTiposIncidencias(val);
            }
        },
        cliente_value_filtro: function(val){
            this.empleado_type = "consulta";
            if(val != null){
                this.getEmpleados(val);
                this.getTiposIncidencias(val);
            }
        },
        'prestamo.aplica_cobro': function(val){
            if(val){
                this.disableDescuento = false;
            }
            else{
                this.disableDescuento = true;
            }
        }
    },
    methods: {
        listar() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            if(this.rol == "empleado"){
                this.columns         = [
                    {
                        label: 'Fecha',
                        name: 'fecha_prestamo',
                        filterable: true
                    },
                    {
                        label: 'Monto',
                        name: 'monto',
                        filterable: true
                    },
                    {
                        label: 'Saldo',
                        name: 'saldo',
                        filterable: true
                    },
                    {
                        label: 'Opciones',
                        name: 'opciones',
                        align:'center',
                        filterable: false
                    }
                ]
            }

            if (this.rol == "root") {
                this.getClientes();
            }
 
            if (this.rol != "root") {
                this.cliente_value_filtro = this.datosLogin.cliente_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.cliente_value_filtro});
            }
            
            if(this.rol == 'empleado'){
                this.filters.AND.push({column:'EMPLEADO_ID', value:this.datosLogin.empleado_id});
            }
        },
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }
            this.isLoading = true;
        },
        abrirModal(accion, data = null){
            this.accion     = accion;
            this.isLoadingModal  = true;
            this.imagen     = "/static/icon/catalogos.png"

            if(this.rol == 'root'){
                this.getClientes();
            }else{
                this.cliente_value = this.datosLogin.cliente_id;
            }


            if(accion == "add"){
                this.tituloModal    = "Registrar préstamo";
                this.imagen         = "/static/modal/prestamoCreate.svg";
                
            } else {
                this.tituloModal                    = "Actualizar préstamo";
                this.imagen                         = "/static/modal/prestamoUpdate.svg";
                this.prestamo.id                    = data.id,
                this.prestamo.cliente_id            = data.cliente_id;
                this.cliente_value                  = data.cliente_id;
                this.prestamo.empleado_id           = data.empleado_id;
                this.prestamo.tipo_incidencia_id    = data.tipo_incidencia_id;
                this.prestamo.descripcion           = data.descripcion;
                this.prestamo.monto                 = data.monto;
                this.prestamo.fecha_prestamo        = data.fecha_prestamo;
                this.prestamo.aplica_cobro          = data.aplica_cobro;
                this.prestamo.descuento             = data.descuento;
                setTimeout(()=>this.$refs.FechaPrestamo.setDate(data.fecha_prestamo),200);
            }
            this.isLoadingModal  = false;
            setTimeout(function(){ document.getElementById("btnModal").click(); }, 100);
        },
        
        cerrarModal() {
            this.dialog     = false;
            this.isSaving   = false;
            this.resetValues();
        },
        resetValues() {
            this.prestamo       = {
                id                  : null,
                cliente_id          : null,
                empleado_id         : null,
                tipo_incidencia_id  : null,
                descripcion         : null,
                monto               : null,
                fecha_prestamo      : null,
                aplica_cobro        : true,
                descuento           : 0,
            }
            if(this.$refs.FechaPrestamo != undefined || this.$refs.FechaPrestamo != null){
                this.$refs.FechaPrestamo.resetDate();
            }
            if(this.$refs.FechaPrestamoBA != undefined || this.$refs.FechaPrestamoBA != null){
                this.$refs.FechaPrestamoBA.resetDate();
            }
            this.$nextTick(() => {
                if(this.$refs.form != null){
                    this.$refs.form.reset();
                }
                
                if (this.rol == "root") {
                    this.cliente_value_filtro = null;
                    this.cliente_value = null;
                    this.itemsEmpleados = [];
                    this.deducciones = [];
                   
                }

                this.empleado_value = null;
                this.tipo_incidencia_value = null;
                this.fecha_prestamo = null;
            });
        },
        setFilters() {
            this.filters = {AND:[]};
            
            let cliente = this.cliente_value_filtro;
            let empleado = this.empleado_value;
            let tipo_incidencia = this.tipo_incidencia_value;
            let fecha_prestamo = this.fecha_prestamo;

            if (this.rol != "root") {    
                cliente = this.datosLogin.cliente_id;
            } 

            this.isLoading = true;

            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente});
            }

            if(empleado != null && empleado != undefined && empleado != ""){
                this.filters.AND.push({column:'EMPLEADO_ID', value:empleado});
            }

            if(tipo_incidencia != null && tipo_incidencia != undefined && tipo_incidencia != ""){
                this.filters.AND.push({column:'TIPO_INCIDENCIA_ID', value:tipo_incidencia});
            }

            if(fecha_prestamo != null && fecha_prestamo != undefined && fecha_prestamo != ""){
                this.filters.AND.push({column:'FECHA_PRESTAMO',operator:'BETWEEN', value:[fecha_prestamo,fecha_prestamo]});
            }
            
            this.paginationData.numberPage = 1;
            this.$apollo.queries.prestamos.refetch();
            this.resetValues();
        },
        eliminar(data){
            Notify.Alert(
                "¿Estás seguro de eliminar este préstamo?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    apiPrestamos.delete(data.id).then(response => {
                        Notify.Success("Préstamo eliminado", "El préstamo ha sido eliminado satisfactoriamente");
                        this.$apollo.queries.prestamos.refetch();
                    })
                    .catch(err => {
                        this.isLoading = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            )
        },
        guardar() {
            this.$refs.form.validate().then(success => {
                this.isSaving = true;
                if(!success) {
                    this.isSaving = false;
                    return;
                }
                let descuento = parseFloat(this.prestamo.descuento);
                let monto = parseFloat(this.prestamo.monto);
                if(descuento > monto){
                    this.isSaving = false;
                    Notify.ErrorToast('El descuento tiene que ser menor o igual que el monto');
                    return;
                }
                this.prestamo.cliente_id = this.cliente_value;

                this.isLoading = true;
                if(this.accion === "add") {
                    apiPrestamos.store(this.prestamo)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El préstamo se guardó satisfactoriamente.");
                        this.$apollo.queries.prestamos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
                else if(this.accion === "update") {
                    apiPrestamos.update(this.prestamo)
                    .then((response) => {
                        Notify.Success("Operación exitosa", "El préstamo se actualizó satisfactoriamente.");
                        this.$apollo.queries.prestamos.refetch();
                        this.cerrarModal();
                        this.$nextTick(() => {
                            this.$refs.form.reset();
                        });
                    })
                    .catch(err => {
                        this.isLoading = false;
                        this.isSaving = false;
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            });
        },
        getClientes(){
            let parametros = { activo:true, paginate:false }
            apiClientes.getClientes(parametros).then((response) => {
                this.itemsClientes = response.data;
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Clientes.");
            });
        },
        async getEmpleados(cliente) {
             if(cliente == null){
                return;
            } 
            
            let params = {};

            if(this.empleado_type == "add"){
                params = {
                    cliente_id : this.cliente_value,
                    tipo : this.empleado_type
                };

            }else if(this.empleado_type == "consulta"){
                params = {
                    cliente_id : this.cliente_value_filtro,
                    tipo : this.empleado_type
                };
            }

            await apiPrestamos.getEmpleados(params)
                .then(response => {
                    this.itemsEmpleados = response.data;
                })
                .catch(error => {
                    console.error(error)
                    Notify.ErrorToast("Ocurrió un error al cargar a los empleados.");
                });
        },
        async getTiposIncidencias(cliente) {
            if(cliente == null){
                return;
            } 

            let params = {
                cliente_id : cliente
            };
            this.deducciones = [];
            await apiPrestamos.getTiposPrestamos(params)
                .then(response => {
                    let format = response.data;
                    if(response.data.length != 0){
                        format.forEach( item => {
                            item.codigoNombre = item.codigo + ' - ' + item.nombre
                            this.deducciones.push(item);
                        })
                    }
                })
                .catch(error => console.error(error));
        },
        vista_abonos(data = null){
            this.$session.remove("datosPrestamo");
            this.$session.set('datosPrestamo',data);
            this.$router.push('/abonos');
        },
        dateFormat(fecha) {
            let fecha2 = fecha.split("-");
            return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
        },
        formatoMoneda(value){
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(value)
        },

         /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

    },
    created(){
        this.listar();
    },
    mounted(){
        this.$apollo.queries.prestamos.skip = false;
    },
    apollo: {
        prestamos: {
            query       : queries.prestamosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereConditions : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'descripcion',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.prestamos;
            },
        }
    }
}
</script>

<style scoped>
    #padre {
        padding-left: 40%;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }

</style>
